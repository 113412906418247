export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  apiEndPoint: "https://wms-api.fitinpart.sg/api",
  printerEndpoint: "http://127.0.0.1:9138",
  fpeWebFrontUrl:"https://www.fitinpart.sg",
  fpImageUrl:"https://wms-api.fitinpart.sg/flags/",
  jnbkUrl:"https://jnbk.net/general/login#1",
  vendorApi: 'https://vendor-api.fitinpart.sg/api'
};
