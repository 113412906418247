import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlSelectComponent } from './bl-select/bl-select.component';
import { SearchPipe } from './search.pipe';
import { SelectDropDownModule } from 'ngx-select-dropdown';



@NgModule({
  declarations: [BlSelectComponent, SearchPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SelectDropDownModule
  ],
  exports: [BlSelectComponent]
})
export class BlSelectModule { }
