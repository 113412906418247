import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  private bannerListData = new BehaviorSubject<any>(null);
  currentBannerDataOb$ = this.bannerListData.asObservable();

  private incomeShipmentModalHide = new BehaviorSubject<any>(null);
  incomeShipmentModalHideObs$ = this.incomeShipmentModalHide.asObservable();

  private topBarDataSubject = new BehaviorSubject<any>(null);
  topBarData$ = this.topBarDataSubject.asObservable();

  private onHoldResultSubject = new Subject<any>();
  onHoldResult$ = this.onHoldResultSubject.asObservable();

  private printingDocumentSubject = new Subject<any>();
  printingDocumentSubject$ = this.printingDocumentSubject.asObservable();

  hints :any;

  constructor() { }

  setHintsData(data) {
    this.hints = data
  }

  updateTopBarData(data: any) {
    this.topBarDataSubject.next(data);
  }

  getHintsData() {
    return this.hints
  }

  bannerData(data:any) {

    this.bannerListData.next(data)
  }

  incomeShipmentonHide(data:boolean) {

    this.incomeShipmentModalHide.next(data)
  }

  onHoldFileResults(data:boolean) {

    this.onHoldResultSubject.next(data);

  }

  printingDocumentProgress(data:any){
    this.printingDocumentSubject.next(data);
  }

}
