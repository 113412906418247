import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-bl-select',
  templateUrl: './bl-select.component.html',
  styleUrls: ['./bl-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BlSelectComponent),
      multi: true
    }
  ]
})
export class BlSelectComponent implements ControlValueAccessor, OnChanges {
  @Input({required: true}) options: any[] = [];
  @Input({required: true}) bindValue = '';
  @Input({required: true}) bindLabel = '';
  @Input({required: true}) label = '';
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  ngOnChanges(){
      // console.log("options",this.options);
      // console.log("bindValue",this.bindValue);
      // console.log("bindLabel",this.bindLabel);
  }

  value = undefined;
  valueObj: any = {};
  inputLabel = '';

  config = {
    displayFn:(item: any) => { return item[this.bindLabel]; },
    displayKey: this.bindLabel, // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 0,
    height: "250px",
    enableSelectAll: false,
  };


  writeValue(id: any): void {
    this.value = id;
    this.valueObj = this.options.find(option => option[this.bindValue] === id);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSerchChange(event: any) {
    this.inputLabel = event;
  }

  onSelectChange(event: any) {
    const option = event.value;
    this.writeValue(option[this.bindValue]);
    this.onChange(option[this.bindValue])
  }


}
