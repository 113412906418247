import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { VersionService } from '../services/version.service';
@Injectable({
    providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
    api = environment.apiEndPoint;

    constructor(private router: Router, private version: VersionService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = JSON.parse(localStorage.getItem('usertoken'));
        const temptoken = JSON.parse(localStorage.getItem('temptoken'));
        const g_warehouse_id = localStorage.getItem('warehouse');


        localStorage.setItem('lastApiUrl', JSON.stringify(request.url));
        if (this.router.url === ('/updatePassword')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${temptoken}`,
                    'Access-Control-Allow-Origin': '*'
                },

            });
            return next.handle(request);
        }

        if (token) {

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                },
                setParams:{
                    'g_warehouse_id': g_warehouse_id
                }
            });
        }

        return next.handle(request).pipe(
            tap((event) => {
              if (event instanceof HttpResponse) {
                const domain = new URL(request.url).hostname.split('.')
                const remoteVersion = event.headers.get('X-UI-Version')

                this.version.updateBackendServer(event.headers.get('X-Backend-Server'))
                if (domain[0] != 'vendor-api' && remoteVersion && remoteVersion != '' && environment.appVersion != remoteVersion) {
                    this.version.updateHasUpdate(true)
                }
              }
            })
        );
    }
}
