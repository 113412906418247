import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RemainCharsPipe } from './helpers/remain-chars.pipe';
import { DisableControlDirective } from './helpers/disable-control.directive';
import { MultilinePipe } from './helpers/multiline.pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DragNDropDirective } from './helpers/drag-ndrop.directive';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AutoFocusDirective } from './helpers/auto-focus.directive';
import { SelectTextDirective } from './helpers/select-text.directive';
import { OnBlurDirective } from './helpers/on-blur.directive';
import { DoubleClickDirective } from './helpers/double-click.directive';
import { InternetDownModalComponent } from './components/internet-down-modal/internet-down-modal.component';
import { SortByPipe } from './helpers/sort-by.pipe';
import { ReportIssueComponent } from './components/report-issue/report-issue.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CheckboxBtnComponent } from './components/checkbox-btn/checkbox-btn.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SearchPipe } from './helpers/search.pipe';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { SanitizeHtmlPipe } from './helpers/sanitize-html.pipe';
import { BarCodeCheckBoxBtnComponent } from './components/bar-code-check-box-btn/bar-code-check-box-btn.component';
import { CommonPaginationComponent } from './components/common-pagination/common-pagination.component';
import { PerPageCommonComponent } from './components/per-page-common/per-page-common.component';
import { CustomMultiSelectDropdownComponent } from './components/custom-multi-select-dropdown/custom-multi-select-dropdown.component';
import { CustomerMultiSelectComponent } from './components/customer-multi-select/customer-multi-select.component';
import { TruncateTextPipe } from './helpers/truncate-text.pipe';
import { BlSelectModule} from './components/bl-select/bl-select.module';
@NgModule({
  declarations: [PaginationComponent, RemainCharsPipe, DisableControlDirective, MultilinePipe, DragNDropDirective, AutoFocusDirective, SelectTextDirective, OnBlurDirective, DoubleClickDirective, InternetDownModalComponent, SortByPipe, ReportIssueComponent, CheckboxBtnComponent, SearchPipe, StarRatingComponent, SanitizeHtmlPipe, BarCodeCheckBoxBtnComponent, CommonPaginationComponent, PerPageCommonComponent, CustomMultiSelectDropdownComponent, CustomerMultiSelectComponent, TruncateTextPipe,],
  imports: [
    CommonModule, BsDatepickerModule.forRoot(), NgxDatatableModule, ModalModule.forRoot(),
    TooltipModule.forRoot(), PopoverModule.forRoot(), TypeaheadModule.forRoot(), SelectDropDownModule, FormsModule,
    ReactiveFormsModule,TabsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    DragDropModule,
    CKEditorModule,
    CollapseModule,
    BlSelectModule

  ], exports: [
    PaginationComponent, FormsModule, ReactiveFormsModule, BsDatepickerModule, DisableControlDirective, MultilinePipe,
     NgxDatatableModule, ModalModule, SelectDropDownModule,SortByPipe,
    TooltipModule, PopoverModule, TypeaheadModule, AutoFocusDirective, OnBlurDirective, DoubleClickDirective,
    ReportIssueComponent,TabsModule, CheckboxBtnComponent,
    DragDropModule,NgMultiSelectDropDownModule,
    CKEditorModule, SearchPipe,CollapseModule, StarRatingComponent,SanitizeHtmlPipe,BarCodeCheckBoxBtnComponent,
     CommonPaginationComponent, PerPageCommonComponent,CustomMultiSelectDropdownComponent,CustomerMultiSelectComponent,TruncateTextPipe,
     BlSelectModule
  ]
})
export class SharedModule { }
