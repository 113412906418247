import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], searchValue: string, bindLabel: string): any[] {
    return value.filter(option => option[bindLabel].toLowerCase().includes(searchValue.toLowerCase()))
  }

}
