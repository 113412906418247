import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ShareDataService } from './share-data.service';
@Injectable({
  providedIn: 'root'
})
export class PrintSlipService {

  api = environment.printerEndpoint;
  serverApi = environment.apiEndPoint;
  xmlReq = new XMLHttpRequest();
  constructor(private http: HttpClient, private notifier: NotifierService, private spinner:NgxSpinnerService,
    private shareDataService: ShareDataService
  ) {

  }

  printerVersion() {
    this.xmlReq.open('GET', `${this.api}`);
    this.xmlReq.send();
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)
      }
    })
  }

  printersList() {
    this.xmlReq.open('GET', `${this.api}/printers`);
    this.xmlReq.send();
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)
      }
    })
  }

  setDefaultPrinter(printerName) {
    const data = new FormData();
    data.append('name', printerName);
    data.append('endpoint', this.serverApi);
    this.xmlReq.open('POST', `${this.api}/printers/set_default`);
    this.xmlReq.send(data);
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)

      }
    })
  }

  printRecievedItem(printerName, payload) {
    const data = new FormData();
    data.append('printer', printerName);
    data.append('id', payload['stock_id']);
    data.append('endpoint', this.serverApi);
    this.xmlReq.open('POST', `${this.api}/print/stock`);
    this.xmlReq.send(data);
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)

      }

    })
  }

  printBoxSticker(printerName, pack_id) {
    const data = new FormData();
    data.append('printer', printerName);
    data.append('id', pack_id);
    data.append('endpoint', this.serverApi);
    this.xmlReq.open('POST', `${this.api}/print/box`);
    this.xmlReq.send(data);
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)

      }
    })
  }

  getPrinters() {
    this.printersList().then(res => {

      if (res['target']['status'] === 200) {
        localStorage.setItem('printers', res['target']['response']);
      } else {
        // this.notifier.notify("error", "Printer Not Found, Please Start the software before printing");
      }


    }).catch(err => {

      // this.notifier.notify("error", "Printer Not Found, Please Start the software before printing");
    })
  }


  printLocationBin(payload){
    const data = new FormData();
    data.append('printer', payload.printerName);
    data.append('location_code', payload.location_code);
    data.append('skip_db_check', payload.skip_db_check);
    data.append('type', payload.type);
    data.append('endpoint', this.serverApi);
    this.xmlReq.open('POST', `${this.api}/print/location`);
    this.xmlReq.send(data);
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)

      }
    })
  }

  printLocationRange(payload){
    const data = new FormData();
    data.append('printer', payload.printerName);
    data.append('from', payload.from);
    data.append('to', payload.to);
    data.append('type', payload.type);
    data.append('endpoint', this.serverApi);
    this.xmlReq.open('POST', `${this.api}/print/location_range`);
    this.xmlReq.send(data);
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)

      }
    })
  }

  printInvoice(printerName: string, order_ids) {

    const data = new FormData();
    data.append('printer', printerName);
    data.append('order_id', order_ids);
    data.append('endpoint', this.serverApi);
    data.append('copies', '2');
    this.xmlReq.open('POST', `${this.api}/print/invoice`);
    this.xmlReq.send(data);
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {
        res(response)
      }
      this.xmlReq.onerror = (error) => {
        rej(error)
      }
    })
  }

  checkVersion() {
    return this.http.get(`${environment.apiEndPoint}/printer/version`)
  }

  printAWB(printerName: string, order_id:any){
    if(printerName === 'skip' || printerName === ''){
      this.notifier.notify('error', 'Please select printer');
      this.spinner.hide();
    }else{
    this.shareDataService.printingDocumentProgress({progress:true, totalFiles:1, status:1})
    const data = new FormData();
    data.append('printer', printerName);
    data.append('order_id', order_id);
    data.append('endpoint', this.serverApi);
    this.xmlReq.open('POST', `${this.api}/print/awb`);
    this.xmlReq.send(data);
    return new Promise((res, rej) => {
      this.xmlReq.onload = (response) => {

        if(response['target']['status'] == 422){
          this.shareDataService.printingDocumentProgress({progress:false, totalFiles:1, status:4});
          setTimeout(()=>{
            this.shareDataService.printingDocumentProgress({progress:false, totalFiles:1, status:0});
          }, 5000);
        }else{
          res(response)
          this.shareDataService.printingDocumentProgress({progress:false, totalFiles:1, status:2});
          setTimeout(()=>{
            this.shareDataService.printingDocumentProgress({progress:false, totalFiles:1, status:0});
          }, 5000);
        }

      }
      this.xmlReq.onerror = (error) => {
        rej(error);
        this.shareDataService.printingDocumentProgress({progress:false, totalFiles:1, status:3});
        setTimeout(()=>{
          this.shareDataService.printingDocumentProgress({progress:false, totalFiles:1, status:0});
        }, 5000);
      }
      this.spinner.hide();

    })
  }
  }
}
